<template>
  <div>
    <vue-form :form="user">
      <vue-input-group
        user-can:update-users
        v-model="user.name"
        prop="name"
        vuex
        @vuex="value => $store.dispatch('user/updateUser', { name: value })"
      />
    </vue-form>
    {{ user }}
  </div>
</template>
<script type="text/javascript">
import { mapGetters } from 'vuex'

export default {
  name: 'Account',

  computed: {
    ...mapGetters('user', ['user'])
  }
}
</script>
